<template>
  <div>
    <qa></qa>
  </div>
</template>

<script>
import Qa from '../components/qa/Qa';
export default {
  components: {
    Qa,
  },
  name: 'about-qa',
};
</script>

<style lang="scss" scoped>
</style>
